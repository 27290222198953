<template>
    <div>
        <List>
            <router-link :to="{ name: 'dashboardOverview' }">
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <div>
                            <DashboardIcon width="24" height="24" />
                        </div>

                        <div>{{ $t('overview') }}</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link :to="{ name: 'dashboardAssets' }">
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <div>
                            <TripMultipleDestinationsIcon
                                width="24"
                                height="24"
                            />
                        </div>

                        <div>{{ $t('shared.assets') }}</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link
                v-if="hasConstructionProjectAccess"
                :to="{ name: 'constructionProjectList' }"
            >
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <div>
                            <ExcavatorIcon width="24" height="24" />
                        </div>

                        <div>{{ $t('constructionProjects') }}</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link :to="{ name: 'dashboardGenericChart' }">
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <div>
                            <LineChartIcon width="24" height="24" />
                        </div>

                        <div>{{ $t('genericChart') }}</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link
                v-if="hasLocationHistoryAccess"
                :to="{ name: 'dashboardLocationHistoryChart' }"
            >
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <div>
                            <TimeClockFileSearch width="24" height="24" />
                        </div>

                        <div>{{ $t('router.locationHistoryChart') }}</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link
                v-if="hasTachographAccess"
                :to="{ name: 'dashboardTachographHistory' }"
            >
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <div>
                            <TachographIcon width="24" height="24" />
                        </div>

                        <div>{{ $t('router.tachograph') }}</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link
                v-if="hasReportingAccess"
                :to="{ name: 'dashboardReports' }"
            >
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <div>
                            <ReportIcon width="24" height="24" />
                        </div>

                        <div>{{ $t('reports') }}</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link
                v-if="hasMaintenanceAccess"
                :to="{ name: 'dashboardMaintenanceHistory' }"
            >
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <div>
                            <TaskListIcon width="24" height="24" />
                        </div>

                        <div>{{ $t('shared.measurements.maintenance') }}</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link v-if="isSuperuser" to="/message-stream">
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <div>
                            <MonitorHeartRateIcon width="24" height="24" />
                        </div>

                        <div>Message Stream</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link v-if="hasRfidAccess" :to="{ name: 'dashboardRfid' }">
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <div>
                            <RfidIcon width="24" height="24" />
                        </div>

                        <div>{{ $t('scanRfid') }}</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link
                v-if="hasUserManagementAccess"
                :to="{ name: 'dashboardUsers' }"
            >
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <div>
                            <UsersIcon width="24" height="24" />
                        </div>

                        <div>{{ $t('users') }}</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link v-if="isStaff" :to="{ name: 'dashboardSettings' }">
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <div>
                            <SettingsSliderIcon width="24" height="24" />
                        </div>

                        <div>{{ $t('settings') }}</div>
                    </div>
                </ListItem>
            </router-link>
        </List>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DashboardIcon from '@/components/icons/DashboardIcon'
import ExcavatorIcon from '@/components/icons/ExcavatorIcon'
import LineChartIcon from '@/components/icons/LineChartIcon'
import List from '@/components/List'
import ListItem from '@/components/ListItem'
import MonitorHeartRateIcon from '@/components/icons/MonitorHeartRateIcon'
import ReportIcon from '@/components/icons/ReportIcon'
import RfidIcon from '@/components/icons/RfidIcon'
import SettingsSliderIcon from '@/components/icons/SettingsSliderIcon'
import TachographIcon from '@/components/icons/TachographIcon'
import TaskListIcon from '@/components/icons/TaskListIcon'
import TimeClockFileSearch from '@/components/icons/TimeClockFileSearch'
import TripMultipleDestinationsIcon from '@/components/icons/TripMultipleDestinationsIcon'
import UsersIcon from '@/components/icons/UsersIcon'

export default {
    name: 'DashboardMenu',
    components: {
        DashboardIcon,
        ExcavatorIcon,
        LineChartIcon,
        List,
        ListItem,
        MonitorHeartRateIcon,
        ReportIcon,
        RfidIcon,
        SettingsSliderIcon,
        TachographIcon,
        TaskListIcon,
        TimeClockFileSearch,
        TripMultipleDestinationsIcon,
        UsersIcon,
    },
    computed: {
        ...mapGetters('auth', [
            'hasConstructionProjectAccess',
            'hasLocationHistoryAccess',
            'hasMaintenanceAccess',
            'hasReportingAccess',
            'hasRfidAccess',
            'hasTachographAccess',
            'hasUserManagementAccess',
            'isStaff',
            'isSuperuser',
        ]),
    },
}
</script>

<i18n>
{
    "en": {
        "constructionProjects": "Construction projects",
        "genericChart": "Data insights",
        "locations": "Locations",
        "overview": "Overview",
        "reports": "Reports",
        "scanRfid": "Scan RFID",
        "settings": "Settings",
        "users": "Users"
    },
    "de": {
        "constructionProjects": "Bauaufträge",
        "genericChart": "Datenanalyse",
        "locations": "Standorte",
        "overview": "Übersicht",
        "reports": "Reports",
        "scanRfid": "RFID scannen",
        "settings": "Einstellungen",
        "users": "Benutzer"
    },
    "fr": {
        "constructionProjects": "Projets de construction",
        "genericChart": "Analyse des données",
        "locations": "Emplacements",
        "overview": "Aperçu",
        "reports": "Rapports",
        "scanRfid": "Scan RFID",
        "settings": "Paramètres",
        "users": "Utilisateurs"
    },
    "it": {
        "constructionProjects": "Construction projects",
        "genericChart": "Grafico generico",
        "locations": "Locatione",
        "overview": "Sommario",
        "reports": "Rapporti",
        "scanRfid": "Scansione RFID",
        "settings": "Configurazione",
        "users": "Utenti"
    }
}
</i18n>

<style lang="scss" scoped>
a {
    &:first-child {
        &.router-link-exact-active {
            .list-item {
                background-color: $color-gray-light;
            }
        }
    }

    &:not(:first-child) {
        &.router-link-active {
            .list-item {
                background-color: $color-gray-light;
            }
        }
    }
}
</style>
